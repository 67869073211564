import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pmr_form = _resolveComponent("pmr-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pmr_form, {
      ref: "pmrFormRef",
      edit: _ctx.edit,
      icon: _ctx.showStepLabel.icon,
      title: _ctx.showStepLabel.title,
      subtitle: _ctx.showStepLabel.subtitle,
      bande: _ctx.bandeInfo
    }, null, 8, ["edit", "icon", "title", "subtitle", "bande"])
  ]))
}