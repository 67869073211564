import { defineComponent, computed,onBeforeMount,ref, onMounted} from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import PmrListe from "./liste/PmrListe.vue"

export default defineComponent({
  name: "pmr-form",
  props: {
     title : {type:String, required : true},
     edit : {type:Boolean, default : false},
     subtitle : {type:String, required : true},
    icon : {type:String, required : true},
    bande: {type: Object, required: false}
  },
  components: {
    PmrListe,
    Field,
    ErrorMessage,
  },
  setup(props) {


    const store   = useStore();
    const typePmr   = ref('');
    const selectTypePmr = ref<HTMLElement>();
    const typePmrRef = ref<HTMLElement>();

    onBeforeMount(() => {
      store .dispatch(Actions.GET_TYPE_MATERIEL);
      if(props.edit){
        typePmr.value = props.bande?.pmr.typePmr;
      }
    })

    const onTypeReseauHasBeenResetted = ($event) => {
      typePmr.value = $event
    }

    onMounted(() => {
    });

    return {
      typePmr,
      onTypeReseauHasBeenResetted,
      selectTypePmr,
      typePmrRef
    };
  }
});

