import { TypePmr } from "../enum/enumeration";
import { Site } from "@/core/models/Site";
import * as Yup from "yup";
import { Materiel } from "@/core/models/Materiel";

interface lignePmr {
  bande : {
    id:string,
    debut:undefined|number,
    fin:undefined|number,
  }
  rxFrequence: number|undefined;
  txFrequence: number|undefined;
  materiels : []
  site : Site
}
export class StepPmr { 
    
     //field 
     observation: string|undefined;
     numeroAutorisation: string|undefined;

     lignes: Array<lignePmr>;
     typePmr: TypePmr| string;

    //constructor 
    constructor() { 
        this.observation = ""
        this.typePmr = ""
        this.numeroAutorisation = undefined
        this.lignes = []
    }  

    static validationSchema(){
      return Yup.object({
        numeroLicence: Yup.string().label("Le n° de licence"),
        pmr: Yup.object().shape
        ({
          numeroAutorisation: Yup.string().required().label("Le n° d'autorisation"),
           typePmr: Yup.string().required().oneOf(Object.values(TypePmr)).label("Le type PMR"),
          observation: Yup.string().notRequired().label("L'observation'"),
          lignes : Yup.array().of(
            Yup.object().shape({})
          ).min(1,'La liste des lignes PMR doit avoir au moins 1 élèment.')
          .strict()
      })
  })
    }
 }