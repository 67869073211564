import { defineComponent, ref, computed,onMounted,watch } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Yup from "@/bundle/validations/YupExpended";
import { ErrorMessage, Field, useForm} from "vee-validate";
import { v4 as uuidv4 } from 'uuid';

interface PmrData {
   id?:string;
   bande:{
    id:string
    libelle:string,
    debut:number|undefined;
    fin:number|undefined;
   };
   bandeAutreFin: number|undefined,
   bandeAutreDebut: number|undefined,
   frequenceTx:number|undefined;
   frequenceRx:number|undefined;
   site : Site;
   materiels : Array<MaterielEntry>
}

interface Bande {
  createdDate: Date|string|undefined;
  lastModifiedDate: Date|string|undefined;
  id: string| undefined;
  libelle: string;
  debut: number;
  fin: number;
}
interface Site {
  code: string;
  nom: string;
  longitude: string;
  latitude: string;
  entGeo : {
    id:string;
  };
}
interface MaterielEntry {
  typeMateriel:{
    id?:string;
    nom?: string;
  }
  quantite:number|undefined
}
import useEmitter from '@/composables/useEmitter'
import { useBande } from "@/composables/useBande";
import { useEntGeos } from "@/composables/useEntGeos";
import { Materiel } from "@/core/models/Materiel";
import  BandeX from "@/core/models/bandeX";

export default defineComponent({
  name: "new-pmr-modal",
  props:{
    typeReseau : {type:String, default :""},

  },
  components: {ErrorMessage, Field},
  setup(props,context) {

    //******/ DONNEES DBT
    const emitter = useEmitter();
    const store = useStore();
    const {getBandeDescription,getBandeParReseau,getDefaultBande, bandeIsAutre} = useBande("pmr");
    const {villesGabonaises,getEntgeoDescription} = useEntGeos();


    const showModal = ref(false);
    const edit = ref(false);
    const loading     = ref<boolean>(false);
    const defaultBande = getDefaultBande();
    
    const pmrData = ref<PmrData>({
      id:"",
      bande:{
        id:"",
        libelle:"",
        debut:undefined,
        fin:undefined,
      },
      bandeAutreDebut : undefined,
      bandeAutreFin : undefined,
      frequenceTx:undefined,
      frequenceRx:undefined,
      site : 
        {
          code: "A",
          nom: "",
          longitude: "",
          latitude: "",
          entGeo : {
            id: ""
          },
        },
      materiels:
      []
    });
    //******/ DONNEES FIN

    //******/ COMPUTED PROPERTIES DBT

    // Retourne la liste des bandes pour un réseau PMR
    const bandes = computed(() => {
      // on retourne le tout
      return  getBandeParReseau(props.typeReseau)
    });

    // Retourne la liste des types de matériels disponibles
    const typeMateriels = computed(() => {
      // on retourne le tout
      var materiels : Array<MaterielEntry> = []
      // On parcours tous les types de materiels du store
      store.getters.typeMateriels.forEach(item => {
        
        var obj :  MaterielEntry = {
          typeMateriel:{
            id : item.id,
            nom : item.nom
          },
          quantite:undefined
        }
        // On ajoute notre objet modifié
        materiels.push(obj)
      })
      return materiels
    });

    // Titre du modal
    const title = computed(() => {
          return edit.value ? "Modification d'un ligne au réseau PMR" : "Ajout d'une ligne au réseau PMR";
    });

    // Titre du boutton d'action
    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });

    // Schema de validation du formulaire
    const validationSchema =  Yup.object().shape({
        id:Yup.string().notRequired().default(''),
        bande : Yup.object().shape({
          id : Yup.string().required().label("La bande" ).default(''),
          libelle : Yup.string().notRequired().label("Le libelle de la bande" ).default(''),
          debut: Yup.number().required().label("La fréquence").default(undefined),
          fin: Yup.number().required().label("La fréquence").default(undefined),
        }).label('La bande').typeError('La bande est obligatoire'),
        bandeAutreFin: Yup.number().when('bande.id', {
          is: (id) => { return (id == defaultBande.id);},
          then: (schema) => schema.required().positive()
          .when('bandeAutreDebut',{
            is : (value) =>  { return (value != undefined && value !='');},
            then:(schema) => schema.test(
              'bande-autre-fin-pgpp',
              '${path} doit être superieure à celle du début',
              (value, context) => value != undefined && value > context.parent.bandeAutreDebut)
          }),
          otherwise: (schema) => schema.notRequired().nullable(),
        }).label("La fréquence de fin"),
        bandeAutreDebut: Yup.number().when('bande.id', {
          is: (id) => { return (id == defaultBande.id);},
          then: (schema) => schema.required().positive()
          .when('bandeAutreFin',{
            is : (value) =>  { return (value != undefined && value !='');},
            then:(schema) => schema.test(
                'bande-autre-dbt-pgpp',
                '${path} doit être inferieur à celle de fin',
                (value, context) => value != undefined && value < context.parent.bandeAutreFin,
              )
          }),
          otherwise: (schema) => schema.notRequired().nullable(),
        }).label("La fréquence de début de bande"),
        frequenceTx: Yup.number().required().positive().when('bande.debut',{
          is: (value) => value != undefined,
          then: (schema) => schema.inclusDansBandeFrequence(pmrData.value.bande.debut,pmrData.value.bande.fin)
        }).label("La fréquence Tx"),
        frequenceRx: Yup.number().required().positive().when('bande.fin',{
          is: (value) => value != undefined,
          then: (schema) =>schema.inclusDansBandeFrequence(pmrData.value.bande.debut,pmrData.value.bande.fin)
        }).label("La fréquence Rx"),
        materiels : Yup.array().of(Yup.object().shape(
          {
            quantite: Yup.number().required().label("La quantité"),
            typeMateriel : Yup.object().shape({
              id : Yup.string().required().label("Le type du matériel" ),
              nom: Yup.string().notRequired().label("Le libellé du type de matériel"),
            })
          })),
        site : Yup.object().shape(
          {
            code: Yup.string().default('A'),
            nom: Yup.string().required().label("Le nom"),
            latitude: Yup.string().latitude().required().label("La latitude"),
            longitude: Yup.string().longitude().required().label("La longitude"),
            entGeo : Yup.object().shape({
             id : Yup.string().required().label("La ville" ),
            })
          }
       )
      }
    );

    //******/ COMPUTED PROPERTIES FIN

    onMounted(() => {
      
    })

    const { resetForm, handleSubmit ,errors, values, meta} = useForm<PmrData>({
      validationSchema: validationSchema
    });

    /**
    * Réinitialisation des données du formulaire
    * @returns void()
    */

    const reset = () => {
      // Réinitialisation des données
      resetForm({values: meta.value.initialValues});
      edit.value = false
      showModal.value = false
    };

    /**
    * Actions à faire à la fermeture du modal
    * @returns void()
    */
    const onClose= () => {
      reset()
    };

    /**
    * Actions à faire à la fermeture du modal
    * @returns void()
    */
    const showOneFrequence = computed(() => {
      return props.typeReseau != 'UHF/VHF (DUPLEX)'
    });
    

   /**
    * Action initiales réalisées avant l'ouverture
    * du modal
    * @param pmr données d'une entrée du réseau pmr
    * @returns void()
    */
    const initModal = ( pmr : any  = undefined) => 
    {
      edit.value = pmr.value == undefined ? false : true
      edit.value ? setDataToForm(pmr.value) : setDataToForm(undefined)
    }

    /**
    * Soumission du formulaire
    * @param values
    * @returns void()
    */
    const submit = handleSubmit((values) => 
    {
        emitter.emit(edit.value ? 'pmrEntryUpdated' : 'newPmrEntryAdded', values);
        showModal.value = false
    });

    /**
    * Actualise les données des inputs de type customisé à l'insertion
    * et à l'édition
    * @param entry
    * @returns void()
    */
    const initSelectInput = (entry) => {
      if(!edit.value)
      {
        pmrData.value.site.entGeo.id = ""
        pmrData.value.bande = {
            id:  "",
            libelle:  '',
            debut:undefined,
            fin:undefined,
          }
      }
      else
      {
        pmrData.value.site.entGeo.id = entry.site.entGeo.id
        pmrData.value.bande = {
          id: entry != undefined ? entry.bande.id : "",
          libelle: entry != undefined ? entry.bande.libelle : '',
          debut:entry != undefined ? entry.bande.debut : undefined,
          fin:entry != undefined ? entry.bande.fin : undefined,
        }
      }
    }

    /**
    * Initialise les valeurs du schema de validation  et du formulaire
    * lors de l'ajout et de la modification
    * @param entry
    * @returns void()
    */
    const setDataToForm = (entry: any = undefined) => {

      resetForm({
        values: {
          id: entry != undefined ? entry.id : uuidv4(),
          bande :
          {
            id: entry != undefined ? entry.bande.id : "",
            libelle: entry != undefined ? entry.bande.libelle : '',
            debut:entry != undefined ? entry.bande.debut : undefined,
            fin:entry != undefined ? entry.bande.fin : undefined,
          },
          bandeAutreFin: entry != undefined ? entry.bandeAutreFin : undefined,
          bandeAutreDebut: entry != undefined ? entry.bandeAutreDebut : undefined,
          frequenceTx: entry != undefined ? entry.frequenceTx : undefined,
          frequenceRx: entry != undefined ? entry.frequenceRx : undefined,
          materiels: entry != undefined ?  entry.materiels  : typeMateriels.value,
          site: {
            code: entry != undefined ?  entry.site.code : "A" ,
            nom: entry != undefined ?  entry.site.nom  : "",
            longitude: entry != undefined ?  entry.site.longitude  : "",
            latitude: entry != undefined ?  entry.site.latitude  : "",
            entGeo: {
              id: entry != undefined ?  entry.site.entGeo.id  : ""
            }
          }
        }
      });
      
      initSelectInput(entry)
      
    }

    watch(() => _.cloneDeep(pmrData.value.frequenceTx), (currentValue, oldValue) => {
      if(showOneFrequence.value)
      pmrData.value.frequenceRx = Number(currentValue)
     })


     watch(() => _.cloneDeep(pmrData.value.bandeAutreFin), (currentValue, oldValue) => {
      if(currentValue != undefined )
      pmrData.value.bande.fin = Number(currentValue)
     })

    watch(() => _.cloneDeep(pmrData.value.bandeAutreDebut), (currentValue, oldValue) => {
      if(currentValue != undefined )
      pmrData.value.bande.debut = Number(currentValue)
     })

    return {
      pmrData,
      showModal,
      validationSchema,
      title,
      loading,
      meta,
      values,
      errors,
      actionButtonText,
      bandes,
      villesGabonaises,
      onClose,
      initModal,
      submit,
      getBandeDescription,
      getEntgeoDescription,
      bandeIsAutre,
      showOneFrequence,
      typeMateriels,
      defaultBande
    };
  }
});
