
import type { ElTable } from 'element-plus'
import NewPmrModal from "@/components/modals/forms/newPmrModal/NewPmrModal.vue"

import { defineComponent, computed,onMounted,ref,watch, inject} from "vue";
import { Field, ErrorMessage,useFieldArray,useField } from "vee-validate";
import useEmitter from "@/composables/useEmitter";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import { AlertMessage } from '@/bundle/AlertMessage';
import _ from 'lodash';
import { TypeMateriel, TypePmr } from '@/core/models/enum/enumeration';
import PmrMateriel from "./PmrMateriel.vue"

    export interface Bande {
        id: string;
    }
    export interface BandeX {
        id: string;
        libelle: string;
        debut: number;
        fin: number;
      }
    export interface EntGeo {
        id: string;
    }

    export interface Site {
        code: string;
        nom: string;
        longitude: string;
        latitude: string;
        entGeo: EntGeo;
    }

    export interface Entry {
        id : string;
        bande: Bande;
        largeurBande: string;
        bandeAutre: string;
        frequencePorteuse: string;
        siteA: Site;
        siteB: Site;
    }

    export interface EntryWithoutKey {
        bande: Bande;
        largeurBande: string;
        bandeAutre: string;
        frequencePorteuse: string;
        sites: Site[];
    }
export default defineComponent({
  name: "pmr-liste",
  props: {
     typeReseau : {type:String, default :""},
     showAction : {type:Boolean, default : true},
     edit : {type:Boolean, default : false},
  },
  components: {
    PmrMateriel,
    Field,
    ErrorMessage,
    NewPmrModal,
  },
  setup(props, context) {

    const emitter = useEmitter();
    const {getVilleDescription} = useEntGeos();
    const {getBandeDescription, bandeLimitIsSet, bandeIsAutre} = useBande("pmr");
    const pmrModal = ref<InstanceType<typeof NewPmrModal>>();
    const typeReseauHasBeenResetted = ref(false)

    const { remove, push,update, fields,replace } = useFieldArray('pmr.lignes');
    const { resetField:resetPmrLignes,validate } = useField('pmr.lignes',value => !!value);

    const editPmr = inject("editPmr")


    onMounted(()=>{
        // set the field as valid and clears errors
        resetPmrLignes();
       // resetPmrBandeId();
    })

    const multipleTableRef = ref<InstanceType<typeof ElTable>>()
    const multipleSelection = ref<Entry[]>([])

    // on écoute l'évenement d'enregistrement de l'ajout d'une lien PMR
    emitter.on('newPmrEntryAdded', (entry) => {
      //On ajoute la nouvelle lien dans la liste
      push(entry)
    })

    // on écoute l'évenement de la modification d'une lien PMR
    emitter.on('pmrEntryUpdated', (entry) => {
      var currentField = fields.value.find((item,index) => {
        //Si un objet à la même clé que notre lien
        var it : any = item?.value
        return it.hasOwnProperty('id') && it.id == entry.id
      })

      if(currentField) update(currentField.key as number,entry)
      
    })

    const hasData = computed(() => {
      return fields.value.length > 0
    })

    const hasNetwork = computed(() => {
      return Object.values(TypePmr).toString().split(",").includes(props.typeReseau)
    })

    const canAddEntry = computed(() => {
      var message = "Vous devez choisir un type de réseau PMR avant d'ajouter une ligne au réseau"
      if(hasNetwork.value)
      {
          message = ""
          return {result:true,message:message}
      }
      return {result:hasNetwork.value,message:message}
    })

    const data = computed(() => {
      let data :any = []
      fields.value.forEach(item => {
        data.push({ ...{}, ...item.value as any})
      })
      return data
    })
    

    const openModal = (entry:Entry|undefined = undefined) => {
      if(canAddEntry.value?.result)
      {
          pmrModal.value!.$vfm.show('pmrModal',entry)
      }
      else
      AlertMessage.onWarning(canAddEntry.value?.message)
   }

    const hasItemSelected = computed(() => {
      return multipleSelection.value.length > 0;
    })

    const removeItem = (index) => {
      remove(index)
    }

    const deleteAllEntries = () => {
      replace([])
    }

    const clearSelection = (rows?: Entry[]) => {
        multipleTableRef.value!.clearSelection()
    }
    
    const deleteSelection = (rows?: Entry[]) => {
        var message = "Souhaitez vous supprimer les lignes de PMR que vous avez introduites.<br/> <strong></strong>"
        message += "<strong>N.B :</strong> Les informations supprimées seront définitivement perdues."
        var title =  "Confirmation de suppréssion"
        var options = {showCancelButton:true}

        AlertMessage.onWarning(message,title,options).then((d) => {
          /* Read more about isConfirmed, isDenied below */
          if(d.isConfirmed){
            // Effacez la selection
            // on recupere tous les ids de la selection
            var indexes : Array<string> = _.map(multipleSelection.value, 'id');
  
            //On parcourt la liste des ids
            indexes.forEach((id,index) => 
            {
              var index : number = 0
              // on parcourt la liste des lignes
              for (let v of fields.value) 
              {
                var it : any = v?.value
                //Si les ids correspondent
                if(it.id == id)
                {
                  //on retire la ligne concernée
                  remove(index);
                  multipleSelection.value = multipleSelection.value.filter(s  => { return s.id != id})
                  // on brise cette boucle
                  break;
                }
                // on incrémente l'index
                index++;
              }
            })
          }
        })
    }

    const handleSelectionChange = (val: Entry[]) => {
      multipleSelection.value = val
    }

    watch(() => props.typeReseau, (newValue, oldValue) => {
      if(props.edit)
      return
       if(hasNetwork.value && hasData.value && typeReseauHasBeenResetted.value == false)
       {
        var message = "En changenat de réseau, les lignes PMR existants seront supprimés. Souhaitez-vous continuer ?"
        message += "<strong>N.B :</strong> Les informations supprimées seront définitivement perdues."
        var title =  "Confirmation de changement de réseau PMR"
        var options = {showCancelButton:true}

        AlertMessage.onWarning(message,title,options).then((d) => {
          if(d.isConfirmed)
          {
            deleteAllEntries()
          }
          else
          {
            context.emit('typeReseauHasBeenResetted',oldValue)
            typeReseauHasBeenResetted.value = true
          }
        })
       }
       else 
       {
        typeReseauHasBeenResetted.value = false
       }
    });
    return {
      hasData,
      data,
      multipleTableRef,
      fields,
      openModal,
      clearSelection,
      getVilleDescription,
      handleSelectionChange,
      getBandeDescription,
      deleteSelection,
      multipleSelection,
      hasItemSelected,
      update,
      hasNetwork,
      pmrModal,
      canAddEntry,
      removeItem,
      deleteAllEntries
    };
  }
})