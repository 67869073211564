
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ObservationRecap from "@/components/customs/ObservationRecap.vue"
import PmrListeRecap from "@/components/networks/pmr/form/liste/PmrListeRecap.vue"

export default defineComponent({
  name: "pmr-recap",
  props: {
     data : {type:Object, default:null}
  },
  setup(props){
    const store   = useStore();

    const hasObservation =computed(() => {
      return props.data.pmr.observation != "" && props.data.pmr.observation != undefined && props.data.pmr.observation != null
    })
    /*
    * Permet de recuperer une entité spécifique
    */
    const getEntGeoById = ((id: string) => {
      return store.getters.entgeos.find( entgeo => {return entgeo.id == id})
    })

    /*
    * Permet de recuperer un type de materiel
    */
    const getTypeMaterielById = ((id: string) => {
      return store.getters.typeMateriels.find( t => {return t.id == id})
    })

        /*
    * Permet de recuperer une entité spécifique
    */
    const showEntGeoByDetails = (id: string, nomVilleInternationale : string) => {
      if(id){
        let entgeo = getEntGeoById(id)
        let international = (nomVilleInternationale  == "" || nomVilleInternationale == undefined) ? false : true
        let nomVille = international ? nomVilleInternationale : entgeo.nom
        let province = !international ? entgeo.parent.parent.nom : ""
        let nomPays = international ?  entgeo.nom : "GABON" 
        return province =="" ? nomVille+", "+nomPays : nomVille+", "+province+", "+nomPays
      }
      return ""

    }
    
    return { 
      showEntGeoByDetails,
      getTypeMaterielById,
      hasObservation
    }
  },
  components: {ObservationRecap,PmrListeRecap},
});
