
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import PmrForm from "@/components/networks/pmr/form/PmrForm.vue";
import { useField } from "vee-validate";

export default defineComponent({
  name: "step-1",
  props: { 
    bandeInfo: Object,
    edit: {type: Boolean, required: false} 
  },

  setup(props){

    // Affichage du nom du tableau
    const pmrFormRef = ref<typeof PmrForm | null>(null);

    // Affichage du nom du tableau
    const showStepLabel = computed(() => 
    {
      let subtitle = "Veuillez préciser les détails du réseau"
      let title = "Réseau PMR",
          icon = "las la-wifi fs-1";
      return {title, subtitle, icon}
    });

    onMounted(() => { 
      if(pmrFormRef.value)
      {        
        // pmrFormRef.value.bande = props.bandeInfo?.fh.bande
      }
      
    });

    watch(pmrFormRef, (n, o) =>{
      if(n){
        if(!props.edit){
          n.typePmr = "HF"
        }
      }
      
    })
        
    return{
      showStepLabel,
      pmrFormRef,
    }
  },
  components: {
    PmrForm
  },
});
